@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bp3-select-popover { max-width:800px; width: 800px; }

.fade-out {
  animation: fadeout 1s;
  -moz-animation: fadeout 1s; /* Firefox */
  -webkit-animation: fadeout 1s; /* Safari and Chrome */
  -o-animation: fadeout 1s; /* Opera */
  animation-fill-mode: forwards;
}
@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}
:root {
  --color-primary: #5539ff;
}

a {
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

button {
  font-family: inherit;
  font-size: 100%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: 500ms;
}

.lang {
  position: absolute;
  top: 0;
  right: 0;
}

.section {
  max-width: 1200px;
  margin: 50px auto;
}

.sectionTitle {
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 40px;
  color: #777777;
}

.title {
  font-family: 'Chakra Petch', sans-serif;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 90px;
  line-height: 105%;
  margin: 0;
}

.subtitle {
  color: #FFFFFF;
  font-size: 1.3em;
  margin-bottom: 50px;
}

.loginButton {
  background: #96fdc6;
  color: #000000;
  border: none;
  cursor: pointer;
}

.featureImagesContainer {
  position: relative;
  max-height: 250px;
  margin-top: 50px;
  margin-bottom: 70px;
}

.featureImage {
  display: flex;
  align-items: center;
  height: 100%;
}

.featureImage img {
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
}

.featureImageCenter {
  width: 40%;
  max-width: 400px;
  margin: 0 auto;
}

.featureImageLeft {
  width: 35%;
  max-width: 300px;
  position: absolute;
  right: 60%;
  top: 0;
  z-index:-1;
}

.featureImageRight {
  width: 35%;
  max-width: 300px;
  position: absolute;
  left: 60%;
  top: 0;
  z-index:-1;
}

.featureCellContainer {
  display: flex;
  justify-content: space-around;
}

.featureCell {
  width: 25%;
  margin-bottom: 50px;
}

.featureCell img {
  width: 100%;
}

.featureName {
  font-family: 'Chakra Petch', sans-serif;
  color: #5539ff;
  color: var(--color-primary);
  font-weight: 400;
  font-size: 1.2em;
  margin: 10px 0;
}

.featureDesc {
  font-size: 0.9em;
  color: #777777;
}

.featureIcon {
  background: #5539ff;
  background: var(--color-primary);
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.featureIcon i {
  color: #FFFFFF;
  font-size:1.2em;
}

.pricing {
  background: #f5f5f5;
  padding: 40px;
  border-radius: 10px;
  margin: 30px;
}

.priceCell {
  color: #5539ff;
  color: var(--color-primary);
  border: 1px solid #5539ff;
  border: 1px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.price {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 3em;
  font-weight: 700;
  text-align: center;
}

.priceLabel {
  margin-top: 5px;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  text-align: center;
}

.priceDesc {
  margin: 0 auto;
  font-size: 0.9em;
  width: 50%;
  color: #777777;
  margin-bottom: 30px;
}

.examplesDesc {
  margin: 0 auto;
  font-size: 0.9em;
  color: #777777;
  text-align: center;
}

.examplesButtons {
  text-align:center;
  margin: 20px 0 80px;
}

.examplesButtons a {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: 500ms;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 0.9em;
  border: 1px solid #5539ff;
  border: 1px solid var(--color-primary);
  color: #5539ff;
  color: var(--color-primary);
}

.examplesButtons a:hover {
  color: #5539ff;
  color: var(--color-primary);
  background: #eeebff;
  text-decoration: none;
}

.getStarted {
  background-color: #5539ff;
  background-color: var(--color-primary);
  width: 100%;
  padding: 50px;
/*   margin-bottom: 40px; */
  text-align: center;
}
.getStarted .sectionTitle {
  color: #ffffff;
}

.footer {
  padding: 1rem 0 0.75rem 0;
  width: 100%;
  background: #5539ff;
  background: var(--color-primary);
  color: #ffffff;
  font-size: 0.8em;
}

.footerInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer a {
  color: #ffffff;
  border-style: none;
  margin-left: 1rem;
}

.footer a:hover {
  background: #5539ff;
  background: var(--color-primary);
  color: #ffffff;
  text-decoration: none;
}

/* animation */

input[type=radio] {
  display: none;
}

.cards {
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.card {
  width: 40%;
  max-width: 400px;
  transition: transform .6s ease;
  cursor: pointer;
}

#item-1:checked ~ .cards #card-3, #item-2:checked ~ .cards #card-1, #item-3:checked ~ .cards #card-2 {
  transform: translatex(-60%) scale(.8);
  opacity: .4;
  z-index: 0;
  position: absolute;
}

#item-1:checked ~ .cards #card-2, #item-2:checked ~ .cards #card-3, #item-3:checked ~ .cards #card-1 {
  transform: translatex(60%) scale(.8);
  opacity: .4;
  z-index: 0;
  position: absolute;
}

#item-1:checked ~ .cards #card-1, #item-2:checked ~ .cards #card-2, #item-3:checked ~ .cards #card-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}

.h-100 { height: 100% }
