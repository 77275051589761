.bp3-select-popover { max-width:800px; width: 800px; }

.fade-out {
  animation: fadeout 1s;
  -moz-animation: fadeout 1s; /* Firefox */
  -webkit-animation: fadeout 1s; /* Safari and Chrome */
  -o-animation: fadeout 1s; /* Opera */
  animation-fill-mode: forwards;
}
@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}
@-moz-keyframes fadeout { /* Firefox */
    from { opacity: 1; }
    to { opacity: 0; }
}
@-webkit-keyframes fadeout { /* Safari and Chrome */
    from { opacity: 1; }
    to { opacity: 0; }
}
@-o-keyframes fadeout { /* Opera */
    from { opacity: 1; }
    to { opacity: 0; }
}